export const syndeca = (widgetData) => {
    if (isCatalogPage(widgetData) && !window.receiveSyndecaMessage) {
        window.location.reload();
    }
};

export const isCatalogPage = (widgetData) => {
    var returnBool = false;
    if (widgetData.content && widgetData.content.indexOf('syndeca') > -1) {
        return true;
    }
    return returnBool;
};

