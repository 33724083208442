export var getTopPersonalizationInfo = (supersWeNeedToLookAt) => {
    var topPersonalizationInfo = {
        favoriteSuperCat:'',
        favoriteSuperCatPoints: 0,
        favoriteBrand: '',
        favoriteBrandPoints: 0
    };
    if(supersWeNeedToLookAt && supersWeNeedToLookAt == 'ALL'){
        supersWeNeedToLookAt = ['410', '420', '425', '430', '435', '440', '445', '450', '470', '480'];
    }
    try {
        var favoriteSuperCatId = 0;
        for(var i = 0; i < supersWeNeedToLookAt.length; i++){
            var thisSuperId = supersWeNeedToLookAt[i];
            var thisPersonalizationData = localStorage.getItem(`personalization${thisSuperId}`);
            if(thisPersonalizationData){
                thisPersonalizationData = thisPersonalizationData.split('&amp;').join('&');
                var thisSuperPoints = 0;
                var thisSupersBrandPointData = thisPersonalizationData.split('|');
                for(var iBrand = 0; iBrand < thisSupersBrandPointData.length; iBrand++){
                    var thisBrandPointData = thisSupersBrandPointData[iBrand].split(';'),
                        thisBrandName = thisBrandPointData[0].indexOf(':') > -1? thisBrandPointData[0].split(':')[1]:thisBrandPointData[0],
                        thisBrandPoints = thisBrandPointData[1]*1;
                    thisSuperPoints += thisBrandPoints;
                }
                if(thisSuperPoints > topPersonalizationInfo.favoriteSuperCatPoints){
                    favoriteSuperCatId = i;
                    topPersonalizationInfo.favoriteSuperCat = thisSuperId;
                    topPersonalizationInfo.favoriteSuperCatPoints = thisSuperPoints;
                }
            }
        }
    } catch (e) {
        console.log(e);
    }
    try {
        var thisSuperId = supersWeNeedToLookAt[favoriteSuperCatId];
        var thisPersonalizationData = localStorage.getItem(`personalization${thisSuperId}`);
        if(thisPersonalizationData){
            thisPersonalizationData = thisPersonalizationData.split('&amp;').join('&');
            var thisSupersBrandPointData = thisPersonalizationData.split('|');
            for(var iBrand = 0; iBrand < thisSupersBrandPointData.length; iBrand++){
                var thisBrandPointData = thisSupersBrandPointData[iBrand].split(';'),
                    thisBrandName = thisBrandPointData[0].indexOf(':') > -1? thisBrandPointData[0].split(':')[1]:thisBrandPointData[0],
                    thisBrandPoints = thisBrandPointData[1]*1;
                if (thisBrandPoints > topPersonalizationInfo.favoriteBrandPoints) {
                    topPersonalizationInfo.favoriteBrand = thisBrandName;
                    topPersonalizationInfo.favoriteBrandPoints = thisBrandPoints;
                }
            }
        }                
    } catch (e) {
        console.log(e);
    }
    return topPersonalizationInfo;
}
export default getTopPersonalizationInfo;