import React from 'react';
import './bambuser-completed-shows.scss';
export const BambuserCompletedShows = (props) => {
	
	const bambuserOnClick = (id, element) => {

		if (!window.initBambuserLiveShopping) {
			window.initBambuserLiveShopping = function(item) {
				window.initBambuserLiveShopping.queue.push(item);
			};
			window.initBambuserLiveShopping.queue = [];
			var scriptNode = document.createElement('script');
			scriptNode['src'] = 'https://lcx-embed.bambuser.com/dillards/embed.js';
			document.body.appendChild(scriptNode);
		}

		window.onBambuserLiveShoppingReady = (player) => {
			player.configure({
				buttons: {
					dismiss: (props.passedAppFunctions.siteFormat != 'tablet') ? player.BUTTON.MINIMIZE : player.BUTTON.CLOSE,
				},
			});
		};

		window.initBambuserLiveShopping({
			showId: id,
			node: element,
			type: 'overlay',
		});
	};
	return (
		<div className='completed-shows-row mt-5'>
			{props.widgetData.headerText != '' ? <h2 className='text-center'>{props.widgetData.headerText}</h2> : ''}
			<div className='row d-flex justify-content-center'>
				{props.widgetData.slotData.map((slot, index) =>
					slot.isEmpty != 'true' && slot.id !== '' ? (
						<div key={'slotdata-' + index} className='col-md-4 mb-5'>
							<div id={`completed-show-${index}`} className='completed-show text-center'>
								{slot.image !== '' ? (
									<button
										className={`d-block btn-none completed-show-lnk-${index}`}
										id={`bambuser-cs-img-${index}`}
										role='button'
										type='button'
										onClick={(e) => {
											bambuserOnClick(slot.id, this);
										}}
									>
										<img id={`completed-show-img-${index}`} src={slot.image} />
									</button>
								) : (
									''
								)}
								{slot.title !== '' ? <h3 className='title font-weight-bold mt-3'>{slot.title}</h3> : ''}
								{slot.date !== '' ? <h4 className='date font-weight-bold'>{slot.date}</h4> : ''}
								{slot.subText !== '' ? <span className='sub-text d-block mb-3'>{slot.subText}</span> : ''}
								{slot.linkText !== '' ? (
									<button
										className='btn btn-tertiary btn-block'
										id={`bambuser-cs-lt-${index}`}
										role='button'
										type='button'
										onClick={(e) => {
											bambuserOnClick(slot.id, this);
										}}
									>
										{slot.linkText}
									</button>
								) : (
									''
								)}
							</div>
						</div>
					) : (
						''
					)
				)}
			</div>
		</div>
	);
};
