export const wellsfargoMobileMenu = () => {
    setTimeout(() => {
        if ($('i[data-toggle-menu=open]').length > 0) {
            $('i[data-toggle-menu=open]').off('click');
            $('i[data-toggle-menu=open]').on('click', function () {
                var $menuTrigger = $('.mobilePage #wellsfargo  #aside_wrapper,.tabletPage #wellsfargo #aside_wrapper'),
                    $body = $('body'),
                    $mobile_header_menu = $('#wellsfargo #mobile_header_menu'),
                    $mobile_title_bar = $('#wellsfargo #mobile_title_bar');
                $menuTrigger.addClass('open');
                if (!$('#backdrop').length > 0) {
                    $body.append('<div id="backdrop"></div>');
                }

                $('#header_promo_static').length > 0 ? $('.zmdi-arrow-left').css({ top: '70px' }) : $('.zmdi-arrow-left').css({ top: '138px' });                
                $('#header_promo_static').length > 0 ? $mobile_header_menu.css({ position: 'relative' }) : $mobile_header_menu.css({ position: 'absolute' });                
                $('#header_promo_static').length > 0 ? $mobile_title_bar.css({ top: '175px' }) : $mobile_title_bar.css({ top: '115px' });                
                $mobile_title_bar.css({ display: 'none' });
            });
        }
        if ($('i[data-toggle-menu=close]').length > 0) {
            $('i[data-toggle-menu=close]').off('click');
            $('i[data-toggle-menu=close]').on('click', function () {
                var $menuTrigger = $('.mobilePage #wellsfargo #aside_wrapper,.tabletPage #wellsfargo  #aside_wrapper'),
                    $body = $('body'),
                    $mobile_header_menu = $('#wellsfargo #mobile_header_menu'),
                    $mobile_title_bar = $('#wellsfargo #mobile_title_bar');
                $menuTrigger.removeClass('open');
                $('#backdrop').remove();
                $mobile_header_menu.css({ position: 'absolute' });                
                $mobile_title_bar.css({ display: 'block' });
            });
            $('i[data-toggle-menu=close]').trigger('click');
        }
        $('.zmdi-arrow-left').prependTo('#sidebar_menu ul li.title');
    }, 600);
};


export const wellsfargoSidebarTitle = () => {
    setTimeout(() => {
        $('#mobile_title_bar').show();
    }, 500);
};

