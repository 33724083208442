import React from 'react';
import {cleanTermForURL} from '../../../../../../../util/cleanTermForURL';

export class SharedCatalogEntryTile extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    generateProductUrl(thisProduct, qvUrl, actualBreadCrumb) {
        if(this.props.fromOutfit){
            return `/p/${thisProduct.nameForURL}/${thisProduct.catentryId}/outfit`;
        }else{
            var returnObject = {};
            
            returnObject.pathname = `/p/${thisProduct.nameForURL}/${thisProduct.catentryId}`;
            if(thisProduct.salePage == 'Y'){
                returnObject.pathname += '/sale';
            }
            returnObject.state = {};
            if(this.state.selectedSwatchGroup && this.state.selectedSwatchGroup[0]){
                returnObject.state.di = this.state.selectedSwatchGroup[0];
            }else{
                returnObject.state.di = thisProduct.fullImage;
            }
            if(qvUrl){
                returnObject.state.qv = true;
            }
            if(actualBreadCrumb){
                returnObject.state.actualBreadCrumb= actualBreadCrumb;
            }
            if(this.props.productIndex){
                returnObject.state.searchPosition= this.props.productIndex;
            }
            if(this.props.passedAppFunctions.getURLParamValue('realSearch') ||  this.props.passedAppFunctions.getURLParamValue('trackAsSearch')){

                returnObject.state.realSearch = this.props.passedAppFunctions.getURLParamValue('realSearch') || 'N' ;
            }

            try{
                if(typeof window != 'undefined' && window.Modernizr && Modernizr.localstorage){
                    if(this.props.passedAppFunctions.navState.indexOf('/search-term/') > -1){
                        //is search page
                        var cleanedTerm = localStorage.getItem('lastSearchTerm');
                        if(cleanedTerm){
                            cleanedTerm = cleanTermForURL(cleanedTerm);
                            var pathForTesting = this.props.passedAppFunctions.navState.split('?')[0];
                            if(pathForTesting.indexOf(`/search-term/${cleanedTerm}/`) > -1 || pathForTesting == `/search-term/${cleanedTerm}`){
                                returnObject.state.fromSearchPage = true;
                            }
                        }
                    }
                }
            }catch(e){}
            returnObject.state.useOldCategoryId = true;
            return returnObject; 
        }
    }
}
export default SharedCatalogEntryTile;
