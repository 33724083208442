import React from 'react';
import {Link} from 'react-router-dom';

export class RegistryIncentives extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    render() {
        var widgetData = this.props.widgetData;
        return (
            <div className="registryIncentives" id="registryIncentives">
                <ForEach var='group' items={widgetData.incentives} index='index'>
                    <div className='row' key={index}>
                        <div className="col-sm-12">
                            <p className="incentiveHeader">{group.heading}</p>
                        </div>
                        <ForEach var="incentive" items={group.items} index='incentiveIndex'>

                                <div className={`col-lg-6 col-md-6 col-sm-12 incentive ${incentiveIndex % 2 == 0 ? 'clear-left' : ''}`} key={incentiveIndex}>
                                    <p className="incentivesTitle">{incentive.name}</p>
                                    <p className="incentivesDesc">{incentive.description.split('#double;').join('"')}</p>
                                    <If test={incentive.pdfLocation}>
                                        <Link to={incentive.pdfLocation}>Download PDF</Link>
                                    </If>
                                </div>

                        </ForEach>
                    </div>
                </ForEach>
            </div>
        );
    }
}

export default RegistryIncentives;
