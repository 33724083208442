import React from 'react';
import {Link} from 'react-router-dom';

export class RegistryHeader extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    render() {
        var widgetData = this.props.widgetData;
        return (
            <div>
                <If test={widgetData.registryNavLinks}>
                    <nav className="linkbar">
                        <ul>
                            <ForEach var='navLink' items={widgetData.registryNavLinks} index='index'>
                                <li key={index}>
                                    <Link to={navLink.href} key={index} rel="nofollow">{navLink.displayText}</Link>
                                </li>
                            </ForEach>
                        </ul>
                    </nav>
                </If>
                <header id="reg_header">
                    <h1 className="reg-logo">Dillard's Registry</h1>
                    <ul>
                        <li>wedding</li>
                        <li><i className="zmdi zmdi-circle"></i></li>
                        <li>baby</li>
                        <li><i className="zmdi zmdi-circle"></i></li>
                        <li>gift</li>
                    </ul>
                </header>
                <If test={widgetData.manageNavLinks}>
                    <nav className="linkbar text-center">
                        <ul>
                            <ForEach var='navLink' items={widgetData.registryNavLinks} index='index'>
                                <Link to={navLink.href} key={index}>{navLink.displayText}</Link>
                            </ForEach>
                        </ul>
                    </nav>
                </If>
            </div>
        );
    }
}

export default RegistryHeader;
