export var addCurrentParamsToCategoryLinkCommon = (inputUrl, thisProps) => {
    var outputUrl = inputUrl.split('&#039;').join(`'`);
    if (thisProps.passedAppFunctions.getURLParamValue('facet')) {
        outputUrl += (outputUrl.indexOf('?') > -1 ? '&' : '?') + 'facet=' + thisProps.passedAppFunctions.getURLParamValue('facet');
    }
    if (thisProps.passedAppFunctions.seoColorInfo.seoColorValue) {
        if (thisProps.passedAppFunctions.getURLParamValue('facet')) {
            outputUrl += '|' + thisProps.passedAppFunctions.seoColorInfo.seoColorValue;
        }else{
            outputUrl += (outputUrl.indexOf('?') > -1 ? '&' : '?') + 'facet=' + thisProps.passedAppFunctions.seoColorInfo.seoColorValue;
        }
    }
    if (thisProps.passedAppFunctions.getURLParamValue('orderBy')) {
        outputUrl += (outputUrl.indexOf('?') > -1 ? '&' : '?') + 'orderBy=' + thisProps.passedAppFunctions.getURLParamValue('orderBy');
    }
    if (thisProps.passedAppFunctions.getURLParamValue('onlyFlags')) {
        outputUrl += (outputUrl.indexOf('?') > -1 ? '&' : '?') + 'onlyFlags=' + thisProps.passedAppFunctions.getURLParamValue('onlyFlags');
    }
    if (thisProps.passedAppFunctions.getURLParamValue('exclude')) {
        outputUrl += (outputUrl.indexOf('?') > -1 ? '&' : '?') + 'exclude=' + thisProps.passedAppFunctions.getURLParamValue('exclude');
    }
    return outputUrl;
}