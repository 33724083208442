import React from 'react';
import SetInnerHTML from '../../../../../UI/SetInnerHTML/SetInnerHTML';

export class ProductExtraVerbiage extends React.Component {
	render() {
        var verbiagesToShow = [];
        if(this.props.contentData){
            //we were fed the contentData object for ease.

            if(this.props.contentData.dropshipVerbiage){
                verbiagesToShow.push(
                    <div className='ds-message' key={verbiagesToShow.length}>  
                        <SetInnerHTML innerHTML={this.props.contentData.dropshipVerbiage}/>
                    </div>
                );
            }
            //auxVerbiage1
            if(this.props.contentData.auxVerbiage1 && this.props.contentData.auxVerbiage1 != ''){
                verbiagesToShow.push(
                    <SetInnerHTML innerHTML={this.props.contentData.auxVerbiage1} key={verbiagesToShow.length} />
                );
            }
            //auxDescription1
            if(this.props.contentData.auxDescription1 && this.props.contentData.auxDescription1 != ''){
                verbiagesToShow.push(
                    <SetInnerHTML innerHTML={this.props.contentData.auxDescription1} key={verbiagesToShow.length} />
                );
            }
            //auxDescription2
            if(this.props.contentData.auxDescription2 && this.props.contentData.auxDescription2 != ''){
                verbiagesToShow.push(
                    <SetInnerHTML innerHTML={this.props.contentData.auxDescription2} key={verbiagesToShow.length} />
                );
            }
            //preOrder.preOrderMessage
            if(this.props.contentData.preOrder && this.props.contentData.preOrder.preOrderMessage && this.props.contentData.preOrder.preOrderMessage != ''){
                verbiagesToShow.push(
                    <div className='h_155f8f' key={verbiagesToShow.length} >{this.props.contentData.preOrder.preOrderMessage}</div>
                );
            }
            //preOrder.EndDateMessage
            if(this.props.contentData.preOrder && this.props.contentData.preOrder.previewEndDateMessage && this.props.contentData.preOrder.previewEndDateMessage != ''){
                verbiagesToShow.push(
                    <div className='h_155f8f' key={verbiagesToShow.length} >{this.props.contentData.preOrder.previewEndDateMessage}</div>
                );
            }
            //limitedAvailability
            if(!this.props.navTile && (this.props.contentData.limitedAvailability == "LIMITED" || this.props.contentData.limitedAvailability == "Limited Availability")) {
                verbiagesToShow.push(
                    <div className='h_155f8f' key={verbiagesToShow.length} >Limited Availability</div>
                );
            }
            if(!this.props.fromQv && this.props.contentData.productContainsMonogramEligibleItems == "true") {
                verbiagesToShow.push(
                    <div className='h_155f8f hide-when-international' key={verbiagesToShow.length} >Monogramming Available for $7.50</div>
                );
            }
            // if((this.props.contentData.productSubscriptionEligible || 'N') == 'Y') {
            //     if (this.props && this.props.passedAppFunctions && this.props.passedAppFunctions.wasParms) {
            //         var subscriptionVerbiage = this.props.passedAppFunctions.wasParms.subscriptionVerbiage;
            //         var subscriptionsEnabled = this.props.passedAppFunctions.wasParms.subscriptionsEnabled;
            //         if (subscriptionsEnabled == 'true' && subscriptionVerbiage != undefined) {
            //             verbiagesToShow.push(
            //                 <span className="h_155f8f hide-when-international" key={verbiagesToShow.length} >{subscriptionVerbiage}</span>
            //             );
            //         }
            //     }
            // }

        }else{
            //we were fed explicitly what we need to display
            //auxVerbiage1
            if(this.props.auxVerbiage1 && this.props.auxVerbiage1 != ''){
                verbiagesToShow.push(
                    <SetInnerHTML innerHTML={this.props.auxVerbiage1} key={verbiagesToShow.length} />
                );
            }
            //auxDescription1
            if(this.props.auxDescription1 && this.props.auxDescription1 != ''){
                verbiagesToShow.push(
                    <SetInnerHTML innerHTML={this.props.auxDescription1} key={verbiagesToShow.length} />
                );
            }
            //auxDescription2
            if(this.props.auxDescription2 && this.props.auxDescription2 != ''){
                verbiagesToShow.push(
                    <SetInnerHTML innerHTML={this.props.auxDescription2} key={verbiagesToShow.length} />
                );
            }
            //preOrderMessage
            if(this.props.preOrderMessage && this.props.preOrderMessage != ''){
                verbiagesToShow.push(
                    <div className='h_155f8f' key={verbiagesToShow.length} >{this.props.preOrderMessage}</div>
                );
            }
        }
        if(this.props.navTile) {
            if(this.props.contentData.limitedAvail){
                verbiagesToShow.push(
                    <div className='h_155f8f' key={verbiagesToShow.length} >{this.props.contentData.limitedAvail}</div>
                );
            }
            if(this.props.contentData.productContainsMonogramEligibleItems == '1'){
                verbiagesToShow.push(
                    <span className="h_155f8f hide-when-international" key={verbiagesToShow.length} >Monogramming Available</span>
                );
            }
        }
        
		return (
            <div>
                {verbiagesToShow}
            </div>
        )
	}
}

export default ProductExtraVerbiage;
