import React from 'react';

export class CatalogEntryListStyleHeight extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.state.imgHeight = '';
		if (typeof window != 'undefined') {
			if (window.cachedCatalogEntryListStyleHeight) {
				this.state.imgHeight = window.cachedCatalogEntryListStyleHeight;
			} else {
				if (this.props.passedAppFunctions.siteFormat == 'desktop') {
					this.state.imgHeight = parseFloat((((document.body.clientWidth * 0.8333 - 90) / 3) * 1.16).toFixed(3));
				}
			}
			if (window.cachedCatalogEntryListStyleHeightMobileImg) {
				this.state.mobileImgHeight = window.cachedCatalogEntryListStyleHeightMobileImg;
			} else {
				if (this.props.passedAppFunctions.siteFormat == 'mobile') {
					this.state.mobileImgHeight = parseFloat((((document.body.clientWidth - 10) / 2) * 1.16).toFixed(3));
				}
			}
		}
		this.updateImgHeight = this.updateImgHeight.bind(this);
	}

	componentDidMount() {
		if ((this.props.passedAppFunctions.siteFormat == 'desktop' && !window.cachedCatalogEntryListStyleHeight) || (this.props.passedAppFunctions.siteFormat == 'mobile' && !window.cachedCatalogEntryListStyleHeightMobileImg))
			this.updateImgHeight();
		}

	updateImgHeight() {
		var newStateToSet = false;
		var maxHeight = 0;
		if ($('.desktopPage').length > 0) {
			var firstNonLazy = $('.LazyLoadIndicator');
			maxHeight = firstNonLazy.height();
			if ($('.NotLazyLoad.is-visible').length > 0) {
				var firstNonLazyNonIndicator = $('.NotLazyLoad.is-visible').eq(0);
				maxHeight = Math.max(maxHeight, firstNonLazyNonIndicator.height());
			}
			if (this.state.imgHeight != maxHeight && maxHeight > 0) {
				newStateToSet = newStateToSet || {};
				newStateToSet.imgHeight = maxHeight;
				window.cachedCatalogEntryListStyleHeight = maxHeight;
			}
		}
		if ($('.mobilePage').length > 0) {
			var firstNonLazyMobileImg = $('.LazyLoadIndicator img').eq(0);
			var mobileImgHeight = 0;
			mobileImgHeight = firstNonLazyMobileImg.height();
			if ($('.NotLazyLoad.is-visible img').length > 0) {
				var firstNonLazyNonIndicatorMobileImg = $('.NotLazyLoad.is-visible img').eq(0);
				maxHeight = Math.max(mobileImgHeight, firstNonLazyNonIndicatorMobileImg.height());
			}
			if (this.state.mobileImgHeight != mobileImgHeight && mobileImgHeight > 0) {
				newStateToSet = newStateToSet || {};
				newStateToSet.mobileImgHeight = mobileImgHeight;
				window.cachedCatalogEntryListStyleHeightMobileImg = mobileImgHeight;
			}
		}

		if (newStateToSet) {
			this.setState(newStateToSet);
		}
	}

	render() {
		if (this.props.passedAppFunctions.siteFormat == 'mobile') {
			var mobileImageWidth = parseFloat((this.state.mobileImgHeight / 1.16).toFixed(3));
		}
		return (
			<style suppressHydrationWarning={true}>{`
				.desktopPage .result-tile-above .LazyLoad {
                    height:${this.state.imgHeight}px !important;
                }
				.mobilePage .result-tile-above {
					width:${mobileImageWidth}px;
                    height:${this.state.mobileImgHeight}px;
                }
            `}</style>
		);
	}
}

export default CatalogEntryListStyleHeight;
