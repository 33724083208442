import React from 'react';
import './bambuser-main-show.scss';
export const BambuserMainShow = (props) => {

	const bambuserOnClick = (id, element) => {
		
		if (!window.initBambuserLiveShopping) {
			window.initBambuserLiveShopping = function(item) {
				window.initBambuserLiveShopping.queue.push(item);
			};
			window.initBambuserLiveShopping.queue = [];
			var scriptNode = document.createElement('script');
			scriptNode['src'] = 'https://lcx-embed.bambuser.com/dillards/embed.js';
			document.body.appendChild(scriptNode);
		}

		window.onBambuserLiveShoppingReady = (player) => {
			player.configure({
				buttons: {
					dismiss: (props.passedAppFunctions.siteFormat != 'tablet') ? player.BUTTON.MINIMIZE : player.BUTTON.CLOSE,
				},
			});
		};
		
		window.initBambuserLiveShopping({
			showId: id,
			node: element,
			type: 'overlay',
		});

	};
	return (
		<div className='main-show-row mb-5 container'>
			<div className='row'>
				<div className='col-md-8 d-flex'>
					<img id='main-show-image' src={props.widgetData.image} />
				</div>
				<div className='col-md-4 d-flex align-items-center'>
					<div className='main-text text-left pl-0'>
						{props.widgetData.title != '' ? <h1 className='text-left'>{props.widgetData.title}</h1> : ''}
						{props.widgetData.subText1 != '' ? <p>{props.widgetData.subText1}</p> : ''}
						{props.widgetData.date != '' ? <p className='font-weight-bold'>{props.widgetData.date}</p> : ''}
						{props.widgetData.id != '' ? (
							<button  
								className='btn btn-primary' 
								id='bambuser-ms-btn'
								onClick={(e) => {
									bambuserOnClick(props.widgetData.id, this);
								}}
							>
								{props.widgetData.buttonTxt != '' ? props.widgetData.buttonTxt : 'Watch Now'}
							</button>
						) : (
							''
						)}
						{props.widgetData.subText2 != '' ? <p>{props.widgetData.subText2}</p> : ''}
					</div>
				</div>
			</div>
		</div>
	);
};
