import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';


export class StoreHomeList extends React.Component{
  shouldComponentUpdate(nextProps) {
		return !_.isEqual(this.props, nextProps);
	}
  urlFormatStoreName(currentStore){
    var formattedStoreName = `${currentStore.storeName}-${currentStore.city}`;
    formattedStoreName = formattedStoreName.toLowerCase();
    formattedStoreName = formattedStoreName.replace(/[^a-zA-Z0-9 .|-]+/, '');
    formattedStoreName = formattedStoreName.split(' ').join('-');
    return formattedStoreName;
  }
 	render(){
     var storeInfo = this.props.stores;
    var seo = this.props.seo;
    var currentState = '';
    var elementArray = [];
    var currentClassName = 'white';
    var ldJSON = {"@context":"http://schema.org","@type":"WebPage","url":"https://www.dillards.com/stores"};
    ldJSON.mainEntity = {"@type":"ItemList","name":"Dillard's Department Stores","itemListElement":[]};
    
    	
    for(var i = 0; i < this.props.stores.length; i++){
      var currentState = this.props.stores[i].stateHeader;
      var currentStateUrl = `/stores/${currentState.toLowerCase().split(' ').join('-')}`;
      var currentDetail = this.props.stores[i].storesDetail;
        for(let x = 0; x < currentDetail.length; x++){
          var currentStore = currentDetail[x];
          var currentStoreUrl = `/stores/${currentState}/${this.urlFormatStoreName(currentStore)}/${currentStore.identifier}`;
          var telLinkPhoneFormat = `(${currentStore.phone.substring(0,3)})${currentStore.phone.substring(3,6)}-${currentStore.phone.substring(6)}`;

          var storeJSONld = {"@type":"DepartmentStore"};
          storeJSONld.image = 'https://dimg.dillards.com/is/image/DillardsZoom/' + currentStore.image1;

          storeJSONld.address = {"@type":"PostalAddress"};
          storeJSONld.address.addressLocality = currentStore.city;
          storeJSONld.address.addressRegion = currentStore.state_abrev;
          storeJSONld.address.postalCode = currentStore.zipcode;
          storeJSONld.address.streetAddress = currentStore.address1;
          
          storeJSONld.name = currentStore.storeName;
          storeJSONld.telephone = `(${currentStore.phone.substring(0,3)})${currentStore.phone.substring(3,6)}-${currentStore.phone.substring(6)}`;
          storeJSONld.url = `https://www.dillards.com/stores/${currentState}/${this.urlFormatStoreName(currentStore)}/${currentStore.identifier}`;
          storeJSONld.priceRange= `$ - $$$`;
          ldJSON.mainEntity.itemListElement.push(storeJSONld);
          let storeNo = currentStore.identifier,
            clearanceStore = storeNo.indexOf('05') == 0,
            clearanceStoreStyle =  clearanceStore ? <em className='text--clearanceStore'> Clearance Store</em> : '';
          }
    }
    var isRealSearch = '';
    if(this.props.passedAppFunctions.getURLParamValue('trackAsSearch') != '') {
    	isRealSearch = "Y"; 
  	} else {
  		isRealSearch = "N";
  	}
    var pageName = '';
		if(this.props.passedAppFunctions.contentSwitchPath == "/stores/:stateName") {
			pageName = 'Store Locator & Events | ' + ((this.props.stateName) ? this.props.stateName : (storeInfo && storeInfo[0] && storeInfo[0].stateHeader) ? storeInfo[0].stateHeader : '');
		} else {
			pageName = 'Store Locator & Events | Dillards.com';
		}
    this.props.passedAppFunctions.setDataLayerInfo('page',{"name":pageName,"categoryId":"-10028","pageId":"Store Locator","type": "Store","RealSearch":isRealSearch, "primaryCategory":"Store", "subCategory1":"","subCategory2":""});
    var helmetProperties = {};
		helmetProperties.meta = [];
    if(seo){
      helmetProperties.title = seo.title;
      helmetProperties.meta.push({"name":"description","content":seo.description.split(`&#039;`).join(`'`)});
      helmetProperties.meta.push({"name":"keywords","content":seo.keywords.split(`&#039;`).join(`'`)});
      helmetProperties.link = [{"rel":"canonical","href":seo.canonical}];
    }
 		return(
      <div>
        <If test={seo} >
          <Helmet {...helmetProperties} />
          {/* <Helmet>
            <meta name="description" content={seo.description.split(`&#039;`).join(`'`)}/>
            <title>{seo.title}</title>
            <meta name="keywords" content={seo.keywords.split(`&#039;`).join(`'`)}/>
            <link rel="canonical" href={seo.canonical} />
          </Helmet> */}
        </If>
        
        <script type="application/ld+json"
          suppressHydrationWarning={true} 
        	dangerouslySetInnerHTML={{__html: JSON.stringify(
        	  {
	            "@context": "http://schema.org",
	            "@type": "BreadcrumbList",
	            "itemListElement":
	              [
	                {
	                  "@type": "ListItem",
	                  "position": 1,
	                  "item":
	                    {
	                      "@id": "https://www.dillards.com/",
	                      "name": "Home Page"
	                    }
	                },
	                {
	                  "@type": "ListItem",
	                  "position": 2,
	                  "item":
	                    {
	                      "@id": "https://www.dillards.com/stores",
	                      "name": "Stores"
	                    }
	                }
	              ]
            }
        )}}>
        </script>
        <script type="application/ld+json" 
          suppressHydrationWarning={true} 
        	dangerouslySetInnerHTML={{__html: JSON.stringify(ldJSON)}}>
        </script>
  
        <div id="storeListing"> 
        { 
          storeInfo && storeInfo.length > 0 ?
           storeInfo.map((state, i)=>
          <div className='col-sm-12 stateHeader' key={state.stateHeader}>
            {
							storeInfo && storeInfo.length > 1 ?
              <Link id={state.stateHeader.replace(' ', '')} className='stateHeading' to={'/stores/'+ state.stateHeader.toLowerCase().split(' ').join('-')} ><h2 className='h2--butlerMedium'>{state.stateHeader}</h2></Link>
              : ''
						}
             {
              state.storesDetail.map((storeDetail,j)=>
              <div className={ `row storeDetail`} key={storeDetail.identifier}>
                <div className='col-sm-12 col-md-3'>
                  <p>{storeDetail.city}</p>
                </div>
                <div className='col-sm-12 col-md-3'>
                  <Link className='underline' to={`/stores/${state.stateHeader.toLowerCase().split(' ').join('-')}/${this.urlFormatStoreName(storeDetail)}/${storeDetail.identifier}`} >{storeDetail.storeName}</Link>
                  <If test={storeDetail.identifier.indexOf('05') == 0}>
                    <em className='text--clearanceStore'> Clearance Center</em>
                  </If>
                  </div>
                <div className='col-sm-12 col-md-3 underline'>
                  
                  <a href={'tel:'+(storeDetail.phone.substring(0,3)) + storeDetail.phone.substring(3,6) + '- ' + storeDetail.phone.substring(6)} >{ '('+ ((storeDetail.phone.substring(0,3)) + ')'+ storeDetail.phone.substring(3,6) + '-' + storeDetail.phone.substring(6)).split(')').join(') ')}</a>
                </div>
                <div className='col-sm-12 col-md-3 underline'>
                 <a
                  target="_blank"
                  href={storeDetail.address3 ? `https://g.page/${storeDetail.address3}?gm` : `https://www.google.com/maps?q=${storeDetail.latitude},${storeDetail.longitude}`}
                 >
                <p>{storeDetail.address1}</p><p>{storeDetail.city}, {storeDetail.state_abrev} {storeDetail.zipcode}</p>
                </a>
                </div>
              </div>
            )
            }
            </div>
          ):''
          }
    </div>
</div>
);
}  
}
export default StoreHomeList;
