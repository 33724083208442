import React, { Component } from 'react';
import axios from 'axios';
import { ProductCarousel } from '../../../../../Shared/Content/Layouts/Widgets/ProductCarousel/ProductCarousel';
import { getTopPersonalizationInfo } from '../../../../../../../util/getTopPersonalizationInfo';

export class HorizontalRecommendation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zone: "",
            rec_attributes: [],
            target_header_txt: "",
            priceNumber: ""
        };
        this.setRecState = this.setRecState.bind(this);
        this.forceRerender = this.forceRerender.bind(this);
        this.forceRenderRecentlyViewed = this.forceRenderRecentlyViewed.bind(this);
        this.forceRenderSolrData = this.forceRenderSolrData.bind(this);
        this.mapArrayToObject = this.mapArrayToObject.bind(this);
        this.showRecs = this.showRecs.bind(this);
    }
    setRecState(rec_product_ids, zone, symbolic, target_id, target_category, rec_attributes, target_attributes, target_header_txt, ab_test_id, rec_category_ids) {
        if(!this.state.didUnmount){
            if (rec_attributes && rec_attributes.length > 0) {
                this.setState({
                    zone: zone,
                    rec_attributes: rec_attributes,
                    target_header_txt: target_header_txt
                });
            } 
            this.props.getRecAttributes((this.state.rec_attributes||[]).length);
        }
    }
    
    mapArrayToObject(stringArrayProducts){
        var namedObjectProducts = [];
        for(var i=0; i < stringArrayProducts.length; i++){
            namedObjectProducts.push({
                'productName': stringArrayProducts[i][0],
                'productUrl': stringArrayProducts[i][1],
                'productImg': `https://dimg.dillards.com/is/image/DillardsZoom/${this.props.productImageTemplate?this.props.productImageTemplate: 'productRec'}/${stringArrayProducts[i][13]}/${stringArrayProducts[i][2].split('dimg').length > -1?stringArrayProducts[i][2].split('/')[stringArrayProducts[i][2].split('/').length - 1].split('.jpg').join('') :stringArrayProducts[i][2] }.jpg`,
                'shipInternational': stringArrayProducts[i][8],
                'productCatentryId': stringArrayProducts[i][9],
                'productBrandName': stringArrayProducts[i][12],
                'keyword': stringArrayProducts[i][13],
                'recInteractionData': stringArrayProducts[i][14],
            });
        }
        return namedObjectProducts;
    }

    shouldComponentUpdate(nextProps, nextState){
		if (this.props.contentData && this.props.contentData.productId != nextProps.contentData.productId) {
			return true;
		}
		if(!_.isEqual(nextState, this.state)){
			return true;
        }
		return false;
	}

    forceRenderRecentlyViewed(){
        var recentlyViewedProducts = [];
        try{
            recentlyViewedProducts = JSON.parse(localStorage.getItem('recentlyViewed')|| []);
            for(var i = 0; i < recentlyViewedProducts.length; i++){
                if(this.props.contentData && this.props.contentData.productId && recentlyViewedProducts[i][9] == this.props.contentData.productId)
                    recentlyViewedProducts.splice(i,1);
            }
        } catch(e){
            localStorage.removeItem('recentlyViewed');
        }
        this.forceRerender(recentlyViewedProducts);
    }

    forceRenderSolrData(category, brand, newArrival, imageTemplate){
        let personalizationUrl = '/data/personalizedProducts?';
        personalizationUrl += category? `superCatId=${category}&`: '';
        if(brand){
        	brand = brand.split('&').join('%2526');
        }        
        personalizationUrl += brand? `mfName=${brand}&`:'';
        personalizationUrl += newArrival? `orderBy=6&newArrivals=true&`: `orderBy=1&newArrivals=false&`;
        personalizationUrl += `productImageTemplate=${imageTemplate}`;
        try{
            var axiosOptions = {
                method: 'get',
                url: personalizationUrl
            };
            axios(axiosOptions).then((ajaxResponse) => {
                if(!this.state.didUnmount){
                    var responseJSON = {};
                try { 
                    responseJSON = typeof ajaxResponse.data.contentData == 'object' ? ajaxResponse.data.contentData : JSON.parse(ajaxResponse.data.contentData); 
                    cmdRespIsJSON = true; 
                } catch (e) { }
                    this.forceRerender(responseJSON.products);
                }
            });
        } catch(e){
            console.log(e);
        }
    }

    forceRerender(products){
        if(!this.state.didUnmount){
        this.setState({'rec_attributes':products});
    }
    }
    componentDidMount() {
        //if we are coming from product recs then pull the rec_attributes array from local storage
        //instead of calling IBM and waiting for them to pass us the rec_attributes
        this.state.didUnmount = false;
        if(this.props.zone == 'PDPZ2_RV' && window.Modernizr && Modernizr.localstorage){
            $(window).on('recentlyViewedUpdate', this.forceRenderRecentlyViewed);
            this.state.zone = 'PDPZ2_RV';
            this.forceRenderRecentlyViewed();
        } else if(this.props.zone == 'SuperCatNewArrivals'){
            this.state.zone = 'SuperCatNewArrivals';
            if(this.props.superCat)
                this.forceRenderSolrData(this.props.superCat, undefined, true, this.props.productImageTemplate);
        } else if(this.props.zone == 'BrandSuperCatNewArrivals'){
            this.state.zone = 'BrandSuperCatNewArrivals';
            if(this.props.superCat || this.props.mfName)
                this.forceRenderSolrData(this.props.superCat, this.props.mfName, true, this.props.productImageTemplate);
        } else if(this.props.zone == 'BrandSuperCatPopular'){
            this.state.zone = 'BrandSuperCatPopular';
            if(this.props.superCat || this.props.mfName)
                this.forceRenderSolrData(this.props.superCat, this.props.mfName, false, this.props.productImageTemplate);
            else
                this.forceRenderSolrData("425", undefined, false, this.props.productImageTemplate);
        } else if(this.props.zone == 'TopSellerSuperCats'){
            this.forceRerender(this.props.products);
        } else {
            $(window).on('showRecs', this.showRecs);
            this.setRecRequestData(
                this.props.zoneName || this.props.zone
                , this.props.zoneModifier || this.props.categoryId || ''
                , this.props.attribute || ''
            );
            this.props.getRecAttributes((this.state.rec_attributes||[]).length);
        }
    }
    componentDidUpdate(){
        this.props.getRecAttributes((this.state.rec_attributes||[]).length);
    }

    showRecs(){
		//triggered from adobe launch rule
		this.formatRecContent(this.props.zoneName || this.props.zone);
	}

    setRecRequestData(zone, categoryId, brand) {
    	if(this.props.passedAppFunctions.wasParms.useProductRecommendations == 'true' && this.props.passedAppFunctions.wasParms.adobeRecsOffByZone.indexOf(zone) == -1){
            try{
                var partNumber = (this.props.productId || null);
                var url = window.location.href.split('?')[0];
                var topPersonalizationInfo = getTopPersonalizationInfo('ALL');

                //setting all rec data into a window scoped variable for adobe sdk migration
                window.recCallData = window.recCallData ? window.recCallData : [];
                if(recCallData.length > 0){
                    if(recCallData[0].url != url){
                        window.recCallData = [];
                        recCallData.push({"url" : url});
                    }
                }else{
                    recCallData.push({"url" : url});
                }
                var thisData = {};
                thisData.zone = zone;
                thisData.category = categoryId;
                thisData.brand = brand;
                thisData.partNumber = partNumber;
                thisData.url = url;
                thisData.productClass = (this.props.contentData && this.props.contentData.dceProductClass) ? this.props.contentData.dceProductClass : '';
                thisData.mic = (this.props.contentData && this.props.contentData.mic) ? this.props.contentData.mic : '';
                thisData.dept = (this.props.contentData && this.props.contentData.dept) ? this.props.contentData.dept : '';
                thisData.decade = (this.props.contentData && this.props.contentData.dept) ? this.props.contentData.dept.slice(0,this.props.contentData.dept.length -1) + 'X' : '';
                thisData.century = (this.props.contentData && this.props.contentData.dept) ? this.props.contentData.dept.slice(0,this.props.contentData.dept.length -2) + 'XX' : '';
                thisData.group = (this.props.contentData && this.props.contentData.dceGroup) ? this.props.contentData.dceGroup : '';
                thisData.name = (this.props.contentData && this.props.contentData.productName) ? this.props.contentData.productName : '';
                thisData.newArrival = (this.props.contentData && this.props.contentData.newArrival) ? this.props.contentData.newArrival : '';
                thisData.favoriteBrand = (topPersonalizationInfo && topPersonalizationInfo.favoriteBrand) ? topPersonalizationInfo.favoriteBrand : '';
                thisData.favoriteCategoryId = (topPersonalizationInfo && topPersonalizationInfo.favoriteSuperCat) ? topPersonalizationInfo.favoriteSuperCat : '';
                thisData.includeWithRecData = true;
                recCallData.push({'HorizontalRecommendation' : thisData});

            }catch(e){
                if(this.props.passedAppFunctions.wasParms.adobeRecsDebug && this.props.passedAppFunctions.wasParms.adobeRecsDebug == 'true') console.log(e);
            }
		}
    }
                
    formatRecContent(zone){
                	try{
            if(window.recContent){
                for(var i = 0; i < window.recContent.length; i++){
					var thisContent = window.recContent[i];
                    if(thisContent.scope === zone){

                        var recInteractionData = {};
						if(thisContent &&
						   thisContent.rawData &&
						   thisContent.rawData.id &&
						   thisContent.rawData.scope &&
						   thisContent.rawData.scopeDetails){
								recInteractionData.recId = thisContent.rawData.id;
								recInteractionData.recZone = thisContent.rawData.scope;
								recInteractionData.recScopeDetails = thisContent.rawData.scopeDetails;
                        }

                        var responseData = JSON.parse(thisContent.content);
                			var rec_attributes = [];
                			
                            if(this.props.passedAppFunctions.wasParms.adobeRecsDebug && this.props.passedAppFunctions.wasParms.adobeRecsDebug == 'true') console.log(responseData.recommendedItems);

                			for(var key in responseData.recommendedItems){
                				var thisRec = responseData.recommendedItems[key];
                				
                				var attrs = [
                                         thisRec.name
                                        ,(thisRec.pageurl.indexOf('dillards.com') > -1) ? thisRec.pageurl.split('dillards.com')[1] : ''
                                        ,(thisRec.imgurl.indexOf('/') > -1) ? thisRec.imgurl.split('/')[thisRec.imgurl.split('/').length -1].split('.jpg').join('') : ''
                                        ,0.0 //average rating
                                        ,'' //brand code
                                        ,'' //number reviews
                                        ,'' //minPrice
                                        ,'' //maxPrice
                                        ,'true' //shipInternational
                                    ,(thisRec.pageurl.indexOf('/') > -1) ? thisRec.pageurl.split('/')[thisRec.pageurl.split('/').length -1] : ''  //catentryId
                                        ,thisRec.dpt
                                        ,thisRec.mic
                                        ,thisRec.brand
                                        ,thisRec.keyword
                                    ,(recInteractionData) ? recInteractionData : {}
                                    ];
                				
                				if(attrs[0].indexOf('$entity') == -1 && attrs[1].length > 0 && attrs[2].length > 0 && attrs[9].length > 0 && attrs[12].length > 0 && attrs[13].length > 0){
                					rec_attributes.push(attrs);
                				}
                			}
                			this.setRecState("rec_product_ids", zone, "symbolic", "target_id", "target_category", rec_attributes, "target_attributes", "target_header_txt", "ab_test_id", "rec_category_ids");
                        window.recContent.splice(i,1);
                		}
                }
            }
        }catch(er){
            if(this.props.passedAppFunctions.wasParms.adobeRecsDebug && this.props.passedAppFunctions.wasParms.adobeRecsDebug == 'true') console.log(er);
        }
    }

    componentWillUnmount(){
		//remove the window scoped function so it doesnt cause a console error
		//just to clean up logs and noop function calls.  
		window[`${this.props.zoneName || this.props.zone
            , this.props.zoneModifier || this.props.categoryId || ''
            , this.props.topBrand || this.props.attribute || ''}_zp`] = function(){};
        $(window).off('recentlyViewedUpdate', this.forceRerender);
        $(window).off('showRecs', this.showRecs);
        this.state.didUnmount = true;
	}
    render() {
        return (
            <If test={this.props.passedAppFunctions.wasParms.useProductRecommendations == 'true'}>
	            <ProductCarousel 
	                productsPerRow={this.props.productsPerRow} 
	                rows={this.props.rows} 
	                scrollable={this.props.scrollable} 
	                title={this.props.title} 
	                showProductTitle={this.props.showProductTitle} 
	                cmsp={this.props.cmsp} 
	                products={this.mapArrayToObject(this.state.rec_attributes)} 
	                passedAppFunctions={this.props.passedAppFunctions}
	                contentData={this.props.contentData}
	                zone={this.props.zoneName || this.props.zone}
	            />
            </If>
        );
    }
}

export default HorizontalRecommendation;
