import React from 'react';
import { Link } from "react-router-dom";
import LazyLoad from "../../../../../../UI/LazyLoad/LazyLoad";

export class SplashImage extends React.Component {
    constructor (props) {
        super(props);
    }

    render () {
        var widgetData = this.props.widgetData;
        return (
            <React.Fragment>
                <If test={!this.props.widgetData.device || this.props.widgetData.device == this.props.passedAppFunctions.siteFormat}>
                    <Choose>
                        <When test={widgetData.imageMap}>
                            <ImageElement widgetData={widgetData} />
                            <map name={widgetData.imageMap.name}
                                suppressHydrationWarning={true}
                                dangerouslySetInnerHTML={{ __html: widgetData.imageMap.areaData.split('#double;').join('"') }}
                                data-espotname=""
                            />
                        </When>
                        <When test={widgetData.link}>
                            <AnchorElement
                                href={widgetData.link.href}
                                cmTag={widgetData.link.cmTag}
                                altText={widgetData.link.altText}
                                qv={false}
                            >
                                <ImageElement widgetData={widgetData} />
                            </AnchorElement>
                        </When>
                        <When test={widgetData.quickview}>
                            <AnchorElement
                                href={widgetData.quickview.href}
                                cmTag={widgetData.quickview.cmTag}
                                altText={widgetData.quickview.altText}
                                qv={true}
                                categoryId={widgetData.quickview.categoryId}
                            >
                                <ImageElement widgetData={widgetData} />
                            </AnchorElement>
                        </When>
                        <Otherwise>
                            <ImageElement widgetData={widgetData} />
                        </Otherwise>
                    </Choose>
                </If>
            </React.Fragment>
        );
    }
}

class ImageElement extends React.Component {
    constructor (props) {
        super(props);
    }
    buildImgElement () {
        return (
            <img
                src={this.props.widgetData.imageSrc}
                alt={this.props.widgetData.altText}
                useMap={this.props.widgetData.imageMap && this.props.widgetData.imageMap.name ? `#${this.props.widgetData.imageMap.name}` : undefined}
                className={` m-t-${this.props.widgetData.margins.top} m-b-${this.props.widgetData.margins.bottom} m-l-${this.props.widgetData.margins.left} m-r-${this.props.widgetData.margins.right} ${this.props.widgetData.displayInLine == 'true' ? 'd-inline-block' : ''}`
                }
            />
        );
    }
    render () {
        return (
            <Choose>
                <When test={this.props.widgetData.isLazyLoad && this.props.widgetData.isLazyLoad != 'false'}>
                    <LazyLoad offsetVertical={1000}>
                        {this.buildImgElement()}
                    </LazyLoad>
                </When>
                <Otherwise>
                    {this.buildImgElement()}
                </Otherwise>
            </Choose>
        );
    }
}

class AnchorElement extends React.Component {
    constructor (props) {
        super(props);
    }
    render () {
        return (
            <Link
                to={{
                    pathname: this.props.href, state:
                        this.props.qv
                            ? { qv: true, categoryId: this.props.categoryId ? this.props.categoryId : '' }
                            : {}
                }}
                manual_cm_sp={this.props.cmTag}
                alt={this.props.altText}
            >
                {this.props.children}
            </Link>
        );
    }
}

export default SplashImage;