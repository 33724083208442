import React from 'react';

export class SharedCategoryNavigation extends React.Component {

    buildStructuredData(categories){
        if(categories){
        	var cleanUrl = this.props.passedAppFunctions.navState.split('<').join('&lt;').split('>').join('&gt;');
        	
            var schemaJSON = {
                "@context": "http://schema.org",
                "@type": "WebPage",
                "url": `https://www.dillards.com${cleanUrl}`,
                "mainEntity": {
                    "@type": "offerCatalog",
                    "name": "Products",
                    "itemListElement":[]
                }
            };

            for(var i = 0; i < categories.length; i++){
                schemaJSON.mainEntity.itemListElement.push({
                        "@type": "ItemList",
                        "name": categories[i].categoryLabel,
                        "url": `https://www.dillards.com${categories[i].categoryLink}`
                    }
                );
            }
            return schemaJSON;
        } else {
            return "";
        }
    }
}
export default SharedCategoryNavigation;
