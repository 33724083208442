export const resizeMap = () => {
	var allDeskmap = document.querySelectorAll('[data-deskmap]');
	if (allDeskmap !== undefined && allDeskmap !== null) {
		var allDeskmap = document.querySelectorAll('[data-deskmap]');
		[].forEach.call(allDeskmap, function(setMap) {
			setMap.setAttribute('usemap', setMap.getAttribute('data-deskmap'));
		});
	}

	if ($('img[usemap]').length > 0) {
		$('img[usemap]').each(function() {
			var mapSelector = $(this).attr('usemap'),
				ratioW = $(this).width() / $(this)[0].naturalWidth;
			if (!isNaN(ratioW) && ratioW !== Infinity && ratioW !== 0) {
				// console.log(mapSelector);
				// console.log(ratioW);
				$(
					"[name='" +
						$(this)
							.attr('usemap')
							.split('#')
							.join('') +
						"']"
				)
				.find('area')
				.each(function() {
					if (!$(this).data('coords')) $(this).data('coords', $(this).attr('coords'));
					var newCoords = '';
					$(
						$(this)
							.data('coords')
							.split(',')
					).each(function() {
						if (newCoords) newCoords += ',';
						newCoords += this * ratioW;
					});
					$(this).attr('coords', newCoords);
				});
			}
		});
	}
};
