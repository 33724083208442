export var generateLinkForFacetCommon = (thisFacetValue, thisProps) => {
    var returnLink = getReturnLink(thisProps);
    var search = thisProps.passedAppFunctions.routeInfo.location.search || '';
    if (thisProps.passedAppFunctions.routeInfo.location.pathname.indexOf('?') > -1) {
        search = '?' + thisProps.passedAppFunctions.routeInfo.location.pathname.split('?').splice(1).join('?');
    }    
    if(thisFacetValue){
        thisFacetValue = thisFacetValue.split('%2B').join('%25252B').split('%26').join('%2526').split('%25252B').join('%252B').split('%C2%AE').join('%25C2%25AE');
    }
    if (!thisFacetValue) {
        //clear all link
        return thisProps.passedAppFunctions.updateUrlWithParams(removeSeoColorFromLink(returnLink + search, thisProps), { pageNumber: false, facet: false });
    } else if (thisFacetValue == 'seoColor' || thisFacetValue == thisProps.passedAppFunctions.seoColorInfo.seoColorValue) {
        //remove link for seoColor
        return removeSeoColorFromLink(returnLink + search, thisProps);
    } else if (thisProps.passedAppFunctions.seoColorInfo.seoColorValue) {
        //adding a facet to a seoColor link
        var intermediate = thisProps.passedAppFunctions.updateUrlWithParams(removeSeoColorFromLink(returnLink, thisProps) + search, { pageNumber: false, facet: thisFacetValue.split('%26').join('%2526')});
        return thisProps.passedAppFunctions.updateUrlWithParams(intermediate, { pageNumber: false, facet: thisProps.passedAppFunctions.seoColorInfo.seoColorValue.split('%2B').join('%25252B').split('%26').join('%2526')});
    } else if (thisProps.passedAppFunctions.contentSwitchPath == '/brand/:manufacturer' || thisProps.passedAppFunctions.contentSwitchPath == '/search-term/:searchTerm' || thisProps.passedAppFunctions.isInternational()) {
        return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: false, facet: thisFacetValue });
    } else {
        //everything else
        var existingFacets = thisProps.passedAppFunctions.getURLParamValue('facet');
        var existingFacetsArray = [];
        var returnFacetArray = [];
        if (existingFacets) {
            existingFacetsArray = existingFacets.split('|');
        }
        for (var i = 0; i < existingFacetsArray.length; i++) {
            if (existingFacetsArray[i] != 'dil_shipinternational:Y') {
                returnFacetArray.push(existingFacetsArray[i]);
            }
        }
        existingFacetsArray = returnFacetArray;


        if (returnFacetArray.length == 0) {
            //no facets yet, lets pretty it if not brand nor size
            var thisFacetInformation = thisProps.facetsJSON[thisFacetValue.split('%3A')[0]];
            var facetNameSeoColor = thisFacetInformation.facetTitle.split(' ').join('-');
            var facetValueSeoColor = '';
            for (var i = 0; i < thisFacetInformation.values.length; i++) {
                var thisFacetValueInformation = thisFacetInformation.values[i];
                if (thisFacetValueInformation.val == thisFacetValue.split('%25252B').join('%2B').split('%2526').join('%26').split('%25252B').join('%252B')) {
                    facetValueSeoColor = thisFacetValueInformation.dsp.toLowerCase().split(' ').join('-').split('/').join('-').split('+').join('').split('"').join('').split('#double;').join('').split('&').join('and');
                }
            }
            if (facetNameSeoColor == 'brand' || facetNameSeoColor == 'size' || (thisFacetValue !== null && thisFacetValue.includes('%5C%22'))) {
                //color or brand, old way
                return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: false, facet: thisFacetValue });
            } else if (facetNameSeoColor == 'color') {
                //color does not use facet name
                return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + '/' + facetValueSeoColor.split('/').join('-') + search, { pageNumber: false, facet: false });                
            } else if (facetNameSeoColor == 'heel-height') {
            	let orderBys = search.match(/\?orderBy=\d{0,}/);
            	if(orderBys !== null) {            					
            		let trackAsSearches = search.match(/\&trackAsSearch=\.{0,}/);
            		if(trackAsSearches === null) {
            			search = '';
            		} else {
            			const urlParams = new URLSearchParams(search);
            			const trackAsSearch = urlParams.get('trackAsSearch');
            			search = "&trackAsSearch=" + trackAsSearch;
            		}            					
            	}            	
            	return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + 'facet' + `${thisFacetValue}` + search, { pageNumber: false, facet: false }).replace('facet','?facet=');                                
            } else {
                //everything else does
                return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + `/${facetNameSeoColor}_${facetValueSeoColor}` + search, { pageNumber: false, facet: false });
            }                        
        } else if (existingFacetsArray.length >= 2) {
            //existing facets more than 2 or only one.  remove it!
            return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: false, facet: thisFacetValue });                                    
        } else {
            //we have two.  check to see if this one is being removed.  If it is, we may need to go pretty for remaining one.  
            //if not being removed, add it to list.  
            if (existingFacetsArray.indexOf(thisFacetValue) > -1 && existingFacetsArray.length == 2) {
                //exists in array, need to maybe pretty other one
                var otherFacetValue = existingFacetsArray[0] == thisFacetValue ? existingFacetsArray[1] : existingFacetsArray[0];
                var thisFacetInformation = thisProps.facetsJSON[otherFacetValue.split('%3A')[0]] || {facetTitle:'',values:[]};
                var facetNameSeoColor = thisFacetInformation.facetTitle.split(' ').join('-');
                var facetValueSeoColor = '';
                for (var i = 0; i < thisFacetInformation.values.length; i++) {
                    var thisFacetValueInformation = thisFacetInformation.values[i];
                    if (thisFacetValueInformation.val == otherFacetValue.split('%25252B').join('%2B').split('%2526').join('%26').split('%25252B').join('%252B')) {
                        facetValueSeoColor = thisFacetValueInformation.dsp.toLowerCase().split(' ').join('-').split('/').join('-').split('+').join('').split('"').join('').split('#double;').join('').split('&').join('and');
                    }
                }
                if(facetValueSeoColor == '' || facetNameSeoColor == 'new-arrivals' || facetNameSeoColor == 'dil_pricereduced'){
                    return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: false, facet: thisFacetValue });
                }else if (facetNameSeoColor == 'brand' || facetNameSeoColor == 'size') {
                    //color or brand, old way
                    return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: false, facet: thisFacetValue });
                } else {
                    //everything else pretty
                    if (facetNameSeoColor == 'color') {
                        //color does not use facet name
                        return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + '/' + facetValueSeoColor.split('/').join('-') + search, { pageNumber: false, facet: false });                        
                    } else if (facetNameSeoColor == 'heel-height') {
                    	return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + 'facet' + `${thisFacetValue}` + search, { pageNumber: false, facet: false }).replace('facet','?facet=');                                                
                    } else {
                        //everything else does
                        return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + `/${facetNameSeoColor}_${facetValueSeoColor}` + search, { pageNumber: false, facet: false });
                    }
                }
            } else {
                //tack it on the list
                return thisProps.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: false, facet: thisFacetValue });
            }
        }
    }
}

var getReturnLink = (thisProps) => {
    var returnLink = thisProps.passedAppFunctions.routeInfo.location.pathname.split('?')[0];
    //if there is a facet in the URL but was not found (old SEO color or attribute thats been deactivated) update returnLink
    if(thisProps.passedAppFunctions.contentSwitchPath == '/c/:pathToken/:pathModifier'){
        if(thisProps.passedAppFunctions.navState.split('/').length == 4 
                && thisProps.passedAppFunctions.navState.indexOf('sale-clearance') == -1
                && thisProps.passedAppFunctions.navState.indexOf('limited-availability') == -1
                && thisProps.passedAppFunctions.navState.indexOf('clearance-') == -1
                && !thisProps.passedAppFunctions.seoColorInfo.seoColorValue){
            var pieces = thisProps.passedAppFunctions.routeInfo.location.pathname.split('/');
            pieces.pop();
            returnLink = pieces.join('/');
        }
    } else if(thisProps.passedAppFunctions.contentSwitchPath == '/brand/:manufacturer/:categoryName/:pathModifier'){
        if(!thisProps.passedAppFunctions.seoColorInfo.seoColorValue){
            var pieces = thisProps.passedAppFunctions.routeInfo.location.pathname.split('/');
            pieces.pop();
            returnLink = pieces.join('/');
        }
    } else if(thisProps.passedAppFunctions.contentSwitchPath == '/search-term/:searchTerm/:categoryName/:pathModifier'){
        if(!thisProps.passedAppFunctions.seoColorInfo.seoColorValue){
            var pieces = thisProps.passedAppFunctions.routeInfo.location.pathname.split('/');
            pieces.pop();
            returnLink = pieces.join('/');
        }
    } else if(thisProps.passedAppFunctions.contentSwitchPath == '/c/:pathToken/:pathModifier/:color'){
        if(!thisProps.passedAppFunctions.seoColorInfo.seoColorValue){
            var pieces = thisProps.passedAppFunctions.routeInfo.location.pathname.split('/');
            pieces.pop();
            returnLink = pieces.join('/');
        }
    }
    return returnLink;
}

var removeSeoColorFromLink = function(theLink, thisProps){
    if(thisProps.passedAppFunctions.seoColorInfo.seoColorValue){
        return theLink.substring(0, theLink.lastIndexOf('/' + thisProps.passedAppFunctions.seoColorInfo.seoColorString)) + theLink.substring(theLink.lastIndexOf('/' + thisProps.passedAppFunctions.seoColorInfo.seoColorString) + ('/' + thisProps.passedAppFunctions.seoColorInfo.seoColorString).length)
    }else{
        return theLink;
    }
}