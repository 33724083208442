import getElementPosition from './getElementPosition';

const isHidden = (element) =>
  element.offsetParent === null;

export default function inViewport(element, container, customOffset) {
  // if (isHidden(element)) {
  //   return false;
  // }

  let top;
  let bottom;
  let left;
  let right;

  top = window.pageYOffset;
  left = window.pageXOffset;
  bottom = top + window.innerHeight;
  right = left + window.innerWidth;

  const elementPosition = getElementPosition(element);

  return (
    top <= elementPosition.top + element.offsetHeight + customOffset.top &&
    bottom >= elementPosition.top - customOffset.bottom
  );
}