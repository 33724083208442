import React from 'react';

export class SharedCatalogEntryList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.state.storedFacet = this.props.passedAppFunctions.getURLParamValue('facet');
		this.state.storedOrderBy = this.props.passedAppFunctions.getURLParamValue('orderBy');
		this.state.storedIsInternational = this.props.passedAppFunctions.isInternational();

		this.updateOrderBy = this.updateOrderBy.bind(this);
		this.generatePaginationLink = this.generatePaginationLink.bind(this);
	}
	shouldComponentUpdate(nextProps, nextState) {
		var returnValue = false;
		if (this.props.passedAppFunctions.isInternational() != this.state.storedIsInternational) {
			this.state.storedIsInternational = this.props.passedAppFunctions.isInternational();
			returnValue = true;
		}
		if (nextProps.passedAppFunctions.quickviewIsOpen) {
			return false;
		}
		if (this.props.passedAppFunctions.siteFormat == 'mobile' && this.state.sortOpen != nextState.sortOpen) {
			returnValue = true;
		}
		if (
			this.props.passedAppFunctions.siteFormat == 'mobile' &&
			!_.isEqual(nextProps.passedAppFunctions.navigationToggles, this.props.passedAppFunctions.navigationToggles)
		) {
			returnValue = true;
		}
		if (this.props.passedAppFunctions.siteFormat == 'mobile') {
			if (
				!_.isEqual(nextProps.passedAppFunctions.seoColorInfo, this.props.passedAppFunctions.seoColorInfo) ||
				this.props.passedAppFunctions.getURLParamValue('facet') != this.state.storedFacet
			) {
				this.state.storedFacet = this.props.passedAppFunctions.getURLParamValue('facet');
				returnValue = true;
			}
		}
		if (this.props.passedAppFunctions.getURLParamValue('orderBy') != this.state.storedOrderBy) {
			this.state.storedOrderBy = this.props.passedAppFunctions.getURLParamValue('orderBy');
			returnValue = true;
		}
		if (this.props.excludeFacets === true || !_.isEqual(nextProps.excludeFacets, nextState.excludeFacets)) {
			returnValue = true;
		}
		return returnValue || !_.isEqual(this.props.widgetData, nextProps.widgetData);
	}
	updateOrderBy(event) {
		var newOrderBy = (event.target.value || event.target.getAttribute('data-value') + '') + '';
		var existingOrderBy = this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort;
		if (
			(this.props.passedAppFunctions.getURLParamValue('orderBy') || this.props.passedAppFunctions.wasParms.defaultNavSort) == '1' &&
			(this.props.passedAppFunctions.getURLParamValue('emphasize') || '').indexOf('$') == 0
		) {
			existingOrderBy = '0';
		}
		if (this.props.passedAppFunctions.navState.indexOf('/c/sale-clearance') > -1 || this.props.passedAppFunctions.navState.indexOf('/c/clearance-') > -1) {
			if (newOrderBy == '3') {
				newOrderBy = '7';
			}
			if (newOrderBy == '4') {
				newOrderBy = '8';
			}
		}
		var validOrderBys = ['0', '1', '3', '4', '5', '6', '7', '8', '9'];
		var returnLink = this.props.passedAppFunctions.routeInfo.location.pathname.split('?')[0];
		var search = this.props.passedAppFunctions.routeInfo.location.search || '';
		if (this.props.passedAppFunctions.routeInfo.location.pathname.indexOf('?') > -1) {
			search =
				'?' +
				this.props.passedAppFunctions.routeInfo.location.pathname
					.split('?')
					.splice(1)
					.join('?');
		}
		if (validOrderBys.indexOf(newOrderBy) > -1 && existingOrderBy != newOrderBy) {
			if (newOrderBy == '1' && this.props.passedAppFunctions.getSuperFromUrl()) {
				//if they request popular, lets clear out personalization for the session
				try {
					if (window.Modernizr && Modernizr.sessionstorage) {
						sessionStorage.setItem('sesPz' + this.props.passedAppFunctions.getSuperFromUrl(), '');
					}
				} catch (e) {}
			}
			if (newOrderBy == '0' && this.props.passedAppFunctions.getSuperFromUrl()) {
				//if they request Just for you, lets let personalization kick in
				try {
					if (window.Modernizr && Modernizr.sessionstorage) {
						sessionStorage.removeItem('sesPz' + this.props.passedAppFunctions.getSuperFromUrl());
					}
				} catch (e) {}
			}
			try {
				if (window.Modernizr && Modernizr.sessionstorage) {
					sessionStorage.setItem(
						'storedSortValue',
						newOrderBy == this.props.passedAppFunctions.wasParms.defaultNavSort || newOrderBy == '0' ? false : newOrderBy
					);
				}
			} catch (e) {
				//error storing sort, let it go...
			}
			this.props.passedAppFunctions.routeInfo.history.push(
				this.props.passedAppFunctions.updateUrlWithParams(false, {
					pageNumber: false,
					orderBy: newOrderBy == this.props.passedAppFunctions.wasParms.defaultNavSort || newOrderBy == '0' ? false : newOrderBy,
					emphasize: newOrderBy == '0' ? '$' + this.props.passedAppFunctions.generatePersonalizationParam() : false,
				})
			);
		}
	}
	generatePaginationLink(newPageNumber) {
		var newPageNumberForUrl = newPageNumber == 1 ? false : newPageNumber;
		var returnLink = this.props.passedAppFunctions.routeInfo.location.pathname.split('?')[0];
		var search = this.props.passedAppFunctions.routeInfo.location.search || '';
		if (this.props.passedAppFunctions.routeInfo.location.pathname.indexOf('?') > -1) {
			search =
				'?' +
				this.props.passedAppFunctions.routeInfo.location.pathname
					.split('?')
					.splice(1)
					.join('?');
		}
		return this.props.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: newPageNumberForUrl });
	}
	buildStructuredDataForProduct(thisProduct) {
		var schemaJSON = {
			'@context': 'http://schema.org/',
			'@type': 'Product',
		};
		schemaJSON.name = thisProduct.originalName;
		schemaJSON.url = `https://www.dillards.com/p/${thisProduct.nameForURL}/${thisProduct.catentryId}`;
		schemaJSON.image = `https://dimg.dillards.com/is/image/DillardsZoom/zoom/${thisProduct.nameForURL}/${thisProduct.fullImage}.jpg`;
		schemaJSON.productID = thisProduct.partNumber;
		schemaJSON.sku = thisProduct.partNumber;
		schemaJSON.brand = { '@type': 'Thing', name: thisProduct.brand };
		schemaJSON.offers = {
			'@type': 'AggregateOffer',
			priceCurrency: 'USD',
			highPrice: parseFloat(thisProduct.pricing.offerPriceMax.replace(',', '')).toFixed(2),
			lowPrice: parseFloat(thisProduct.pricing.offerPriceMin.replace(',', '')).toFixed(2),
		};
		if (thisProduct.numReviews && thisProduct.numReviews > 0 && thisProduct.numStars) {
			schemaJSON.aggregateRating = { '@type': 'AggregateRating', ratingValue: thisProduct.numStars, reviewCount: thisProduct.numReviews };
		}

		return schemaJSON;
	}
	buildStructuredDataForProductList() {
		var schemaJSON = [];
		for (var i = 0; i < this.props.widgetData.products.length; i++) {
			schemaJSON.push(this.buildStructuredDataForProduct(this.props.widgetData.products[i]));
		}
		return schemaJSON;
	}
}

export default SharedCatalogEntryList;
