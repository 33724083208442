export const faqsInit = (passedAppFunctions) => {

	// faq scroll to link
	if (document.URL.indexOf('faqs-notices-policies') > 0) {
		setTimeout(() => {
			if (!document.querySelector('#sectionScrollLinks.loaded')) {
				var faqTopicLinks = document.querySelectorAll('.linkToSection');
				faqTopicLinks.forEach(function (link) {
					link.addEventListener('click', function (event) {
						var selectedLink = event.target.getAttribute('data-name');
						var linkTarget = document.getElementById(selectedLink);
						var yOffset = document.querySelector('body.desktopPage') != null ? -120 : 0;
						if (linkTarget != null) {
							var scrollToLink = linkTarget.getBoundingClientRect().top + window.pageYOffset + yOffset;
							window.scrollTo({ top: scrollToLink, behavior: 'smooth' });
						}
					});
				});
				document.getElementById('sectionScrollLinks').classList.add('loaded');
			}
		}, 500);
	}

	setTimeout(() => {
		if (document.URL.indexOf('faqs-notices-policies') > 0 && document.URL.indexOf('#q=') > 1) {
			var headerOffset = 0;
			window.Modernizr && Modernizr.mq('(min-width: 768px)') ? (passedAppFunctions.siteFormat == "desktop" ? headerOffset = 120 : headerOffset = 0) : (headerOffset = 0);
			var scrollToParam = document.URL.split('#q=')[1];
			scrollToParam = scrollToParam.split('&')[0];
			var selectedQuestion = $('#' + scrollToParam);
			if (selectedQuestion.length > 0) {
				passedAppFunctions.routeInfo.history.replace('/c/' + String(window.location).split('#')[0].split('/c/')[1]);
				var checkExist = setInterval(function () {
					$('html, body').animate(
						{
							scrollTop: selectedQuestion.offset().top - headerOffset,
						},
						700
					);
					clearInterval(checkExist);
				}, 600);
			}
			$(window).off('hashchange.asdasdasd');
			$(window).on('hashchange.asdasdasd', () => {
				if (document.URL.indexOf('faqs-notices-policies') > 0 && document.URL.indexOf('#q=') > 1) {
					var headerOffset = 0;
					window.Modernizr && Modernizr.mq('(min-width: 768px)') ? (headerOffset = 115) : (headerOffset = 0);
					var scrollToParam = document.URL.split('#q=')[1];
					scrollToParam = scrollToParam.split('&')[0];
					var selectedQuestion = $('#' + scrollToParam);
					if (selectedQuestion.length > 0) {
						passedAppFunctions.routeInfo.history.replace('/c/' + String(window.location).split('#')[0].split('/c/')[1]);
						var checkExist = setInterval(function () {
							$('html, body').animate(
								{
									scrollTop: selectedQuestion.offset().top - headerOffset,
								},
								700
							);
							clearInterval(checkExist);
						}, 500);
					}
				}
			});
			return false;
		}
	}, 500);
};
