import React, { Children, Component } from 'react';
import { findDOMNode } from 'react-dom';
import { add, remove } from 'eventlistener';
import inViewport from './utils/inViewport';

export default class LazyLoad extends Component {
  constructor (props) {
    super(props);
    this.lazyLoadHandler = this.lazyLoadHandler.bind(this);
    this.handleIntersection = this.handleIntersection.bind(this);
    this.state = { visible: false };
    this.lazyRef = React.createRef();
  }
  componentDidMount () {
    this._mounted = true;

    if (window.IntersectionObserver) {
      var observerSettings = {
        rootMargin: `${this.props.offsetVertical}px 0px ${this.props.offsetVertical}px 0px`,
        threshold: 0.1
      };

      this.observer = new window.IntersectionObserver(this.handleIntersection, observerSettings);
      this.observer.observe(this.lazyRef.current);
    } else {
      // if(!window.doNotCheckForOffset){
      //   if(window.pageYOffset > 300){
      //     this.lazyLoadHandler();
      //   }else{
      //     window.doNotCheckForOffset = true;
      //     setTimeout(function(){
      //       window.doNotCheckForOffset = false;
      //     }, 200);
      //   }
      // }
      // this.lazyLoadHandler = _.throttle(this.lazyLoadHandler, 200);
      this.lazyLoadHandler = this.lazyLoadHandler;
      add(window, 'resize', this.lazyLoadHandler);
      add(window, 'scroll', this.lazyLoadHandler);
    }
  }
  handleIntersection (entries) {
    for (var i = 0; i < entries.length; i++) {
      if (entries[i].isIntersecting) {
        this.setState({ visible: true });
        this.detachListeners();
      }
    }
  }
  componentWillReceiveProps () {
    if (!this.state.visible) {
      this.lazyLoadHandler();
    }
  }
  shouldComponentUpdate (_nextProps, nextState) {
    return nextState.visible;
  }
  componentWillUnmount () {
    this._mounted = false;
    if (this.lazyLoadHandler.cancel) {
      this.lazyLoadHandler.cancel();
    }

    this.detachListeners();
  }
  getOffset () {
    const {
      offset, offsetVertical, offsetHorizontal,
      offsetTop, offsetBottom, offsetLeft, offsetRight, threshold,
    } = this.props;

    const _offsetAll = threshold || offset;
    const _offsetVertical = offsetVertical || _offsetAll;
    const _offsetHorizontal = offsetHorizontal || _offsetAll;

    return {
      top: offsetTop || _offsetVertical,
      bottom: offsetBottom || _offsetVertical,
      left: offsetLeft || _offsetHorizontal,
      right: offsetRight || _offsetHorizontal,
    };
  }
  lazyLoadHandler () {
    if (!this._mounted) {
      return;
    }
    //only execute this if not using IntersectionObserver mode
    if (!window.IntersectionObserver) {
      const offset = this.getOffset();
      const node = findDOMNode(this);

      if (inViewport(node, window, offset)) {
        const { onContentVisible } = this.props;

        this.setState({ visible: true }, () => {
          if (onContentVisible) {
            onContentVisible();
          }
        });
        this.detachListeners();
      }
    }
  }
  detachListeners () {
    if (!window.IntersectionObserver) {
      remove(window, 'resize', this.lazyLoadHandler);
      remove(window, 'scroll', this.lazyLoadHandler);
    } else if (this.observer) {
      this.observer.disconnect();
    }
  }
  render () {
    const { children, className, height, width } = this.props;
    const { visible } = this.state;

    const elStyles = { height, width };
    const elClasses = (
      'LazyLoad' +
      (visible ? ' is-visible' : '') +
      (className ? ` ${className}` : '')
    );


    return React.createElement('div', {
      ref: this.lazyRef,
      className: elClasses,
      style: elStyles,
    }, visible && Children.only(children));
  }
}
