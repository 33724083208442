import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
import Slider from '../../../../../../../customModules/react-slick';

export class ArrowLeftButton extends Component {
	render() {
		return (
			<>
				<button
					onClick={this.props.onClick}
					className={this.props.className}
					disabled={this.props.className.indexOf('slick-disabled') > -1}
					type='button'
					data-role='none'>
					<span className='sr-only'>Previous</span>
				</button>
			</>
		);
	}
}

export class ArrowRightButton extends Component {
	render() {
		return (
			<>
				<button
					onClick={this.props.onClick}
					className={this.props.className}
					disabled={this.props.className.indexOf('slick-disabled') > -1}
					type='button'
					data-role='none'>
					<span className='sr-only'>Next</span>
				</button>
			</>
		);
	}
}

export class ProductCarousel extends React.Component {
	constructor(props) {
		super(props);
	}

	// componentDidMount() {
	// 	this.lazyLoadCarousel();
	// 	window.addEventListener('scroll', _.debounce(this.lazyLoadCarousel));
	// }
	// componentDidUpdate() {
	// 	this.lazyLoadCarousel();P
	// 	window.addEventListener('scroll', _.debounce(this.lazyLoadCarousel));
	// }
	// componentWillReceiveProps() {
	// 	this.lazyLoadCarousel();
	// 	window.addEventListener('scroll', _.debounce(this.lazyLoadCarousel));
	// }
	// componentWillUnmount() {
	// 	window.removeEventListener('scroll', this.lazyLoadCarousel);console.log("4");
	// }
	// lazyLoadCarousel = () => {
	// 	setTimeout(() => {
	// 		var lazyImages = [].slice.call(document.querySelectorAll('.productRecs'));
	// 		if (lazyImages !== null) {
	// 			var advanceLoad = 400;
	// 			lazyImages.forEach(function(lazyImage) {
	// 				if (lazyImage.getBoundingClientRect().top <= window.innerHeight + advanceLoad && lazyImage.getBoundingClientRect().bottom >= 0) {
	// 					var img = [].slice.call(lazyImage.querySelectorAll('.slick-slide img'));
	// 					img.forEach(function(i) {
	// 						if (i.dataset.original !== undefined) {
	// 							i.src = i.dataset.original;
	// 							img = img.filter(function(image) {
	// 								return image !== i;
	// 							});
	// 						}
	// 					});
	// 				}
	// 			});
	// 		}
	// 	}, 0);
	// };
	getTitle = () => {
		return this.props.title ? this.props.title.trim() : '';
	};

	render() {
		//productsPerRow, rows, scrollable, products, title, showProductTitle, cmsp
		var carouselTile = this.getTitle()
			.replace(/\s+/g, '-')
			.toLowerCase();
		if (this.props.passedAppFunctions.siteFormat == 'tablet') {
			var slidesToShow = 4.5; //this.props.productsPerRow + 0.5;
			//var slidesToShow = this.props.productsPerRow + 0.5;
			
			if (this.props.zone == 'BrandSuperCatPopular') {
				var slidesToShow = 3.5;
			}
			if (this.props.zone == 'JFYZ1') {
				var slidesToShow = 3.5;
			}
			
			var settings = {
				dots: false,
				infinite: false,
				vertical: false,
				speed: 500,
				slidesToShow: slidesToShow,
				slidesToScroll: 1,
				swipeToSlide: true,
				swipe: true,
				lazyLoad: true,
				arrows: false,
				rows: this.props.rows,
				slidesPerRow: 1,
				zone: this.props.zoneName || this.props.zone,
				afterChange: (index) => {
					var lazyImages = [].slice.call(document.querySelectorAll('.productRecs'));
					if (lazyImages !== null) {
						lazyImages.forEach(function(lazyImage) {
							var img = [].slice.call(lazyImage.querySelectorAll('.slick-slide img'));
							img.forEach(function(i) {
								if (i.dataset.original !== undefined) {
									i.src = i.dataset.original;
									img = img.filter(function(image) {
										return image !== i;
									});
								}
							});
						});
					}
				},
			};
		} else if (this.props.passedAppFunctions.siteFormat == 'mobile') {
			var slidesToShow = 2.5;
			
			var settings = {
				dots: false,
				infinite: false,
				vertical: false,
				speed: 500,
				slidesToShow: this.props.productsPerRow === 1 ? 1 : slidesToShow,
				slidesToScroll: this.props.productsPerRow === 1 ? 1 : 2,
				swipe: this.props.scrollable,
				lazyLoad: true,
				arrows: this.props.scrollable,
				zone: this.props.zoneName || this.props.zone,
				afterChange: (index) => {
					var lazyImages = [].slice.call(document.querySelectorAll('.productRecs'));
					if (lazyImages !== null) {
						lazyImages.forEach(function(lazyImage) {
							var img = [].slice.call(lazyImage.querySelectorAll('.slick-slide img'));
							img.forEach(function(i) {
								if (i.dataset.original !== undefined) {
									i.src = i.dataset.original;
									img = img.filter(function(image) {
										return image !== i;
									});
								}
							});
						});
					}
				},
			};
		} else {
			var settings = {
				dots: false,
				infinite: false,
				vertical: false,
				speed: 500,
				slidesToShow: this.props.productsPerRow,
				slidesToScroll: 1,
				swipe: this.props.scrollable,
				lazyLoad: true,
				arrows: this.props.scrollable && this.props.products.length > this.props.productsPerRow,
				prevArrow: <ArrowLeftButton />,
				nextArrow: <ArrowRightButton />,
				rows: this.props.rows,
				slidesPerRow: 1,
				zone: this.props.zoneName || this.props.zone,
				afterChange: (index) => {
					var lazyImages = [].slice.call(document.querySelectorAll('.productRecs'));
					if (lazyImages !== null) {
						lazyImages.forEach(function(lazyImage) {
							var img = [].slice.call(lazyImage.querySelectorAll('.slick-slide img'));
							img.forEach(function(i) {
								if (i.dataset.original !== undefined) {
									i.src = i.dataset.original;
									img = img.filter(function(image) {
										return image !== i;
									});
								}
							});
						});
					}
				},
			};
		}
		return (
			<div
				className={`productRecs ${settings.arrows ? 'has-arrows' : ''} ${
					this.props.contentData ? (this.props.contentData.parentFile == 'TopCategoriesDisplay' ? 'splashProdRecs' : '') : ''
				}`}
				role='region'
				aria-label='suggestion carousel'
			>
				<div id='product-carousel' className={this.getTitle().replace(/\s+/g, '-').toLowerCase()}>
					<If test={this.props.products.length > 0}>
						<If test={this.getTitle()}>
							<h2 className={`${this.getTitle().length == 0 ? 'horzRecNoBorder' : 'pr-title-wrapper'}`}>
								<span className='pr-title'>{this.getTitle()}</span>
							</h2>
						</If>
						<Slider {...settings}>
							{this.props.products && this.props.products.length
								? this.props.products.map((product, index) => (
										<If test={!(this.props.contentData && this.props.contentData.productId && this.props.contentData.productId == product.productCatentryId)}>
											{/* do not add the rec for the product who's page contains the recently viewed widget */}
											<figure className='item' key={product.productCatentryId}>
												<Link
													to={{
														pathname: product.productUrl,
														state: {
															categoryId: this.props.zone ? this.props.zone : this.props.zoneName ? this.props.zoneName : '',
															qv: product.productName.toLowerCase().indexOf('chanel') == -1 && product.productName.toLowerCase().indexOf('egift') == -1,
														},
													}}
													onClick={() => window.triggerTargetInteraction(product.recInteractionData)}
													manual_cm_sp={this.props.cmsp || null}
												>
													<img src={product.productImg} alt={product.productName.split('&trade;').join('™')} width='100%' height='100%' />
												</Link>
												<If test={this.props.showProductTitle}>
													<Link
														to={{
															pathname: product.productUrl,
															state: {
																categoryId: this.props.zone ? this.props.zone : this.props.zoneName ? this.props.zoneName : '',
																qv: product.productName.toLowerCase().indexOf('chanel') == -1 && product.productName.toLowerCase().indexOf('egift') == -1,
															},
														}}
														onClick={() => window.triggerTargetInteraction(product.recInteractionData)}
														manual_cm_sp={this.props.cmsp || null}
														className='item-title'
													>
														{product.productBrandName.split('#double;').join('"').split('&quot;').join('"')}
													</Link>
												</If>
											</figure>
										</If>
								  ))
								: ''}
						</Slider>
					</If>
				</div>
			</div>
		);
	}
}

export default ProductCarousel;
